import * as React from "react";

import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faIdCard, faPhone } from "@fortawesome/free-solid-svg-icons";

const metadata = {
  title: "Soto Investigations | Contact",
  description: "Reach out to us.",
  robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  canonical: "/",
  "og:locale": "en_US",
  "og:type": "website",
  "og:title": "Soto Investigations | Contact",
  "og:description": "Contact us.",
  "og:url": "/about",
  "og:site_name": "Soto Investigations",
  "og:image": "/src/assets/logo.png",
  "article:modified_time": "2022-03-06T13:57:48+00:00",
  "twitter:card": "summary_large_image",
  "twitter:label1": "Est. reading time",
  "twitter:data1": "1 minute",
};

const IndexPage = () => (
  <Layout metadata={metadata}>
    <div className="bg-blue-navy h-full flex flex-col items-center pb-4">
      <div className="max-w-5xl text-primary flex flex-col mt-8 w-full px-4">
        <div className="mb-8">
          <div className="uppercase text-sm font-bold mb-4">Contact Us</div>
          <div className="md:flex">
            <div className="ml-8 mr-4 flex-1">
              <div className="mb-2">
                <a href="tel:+12107734905">
                  <button type="button" className="mr-3" aria-label="Call Button">
                    <FontAwesomeIcon icon={faPhone} size="1x" />
                  </button>
                  <span>210-773-4905</span>
                </a>
              </div>
              <div className="mb-2">
                <a href="mailto:chrissotoconsulting@gmail.com">
                  <button type="button" className="mr-3" aria-label="Call Button">
                    <FontAwesomeIcon icon={faEnvelope} size="1x" />
                  </button>
                  <span>chrissotoconsulting@gmail.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="uppercase text-sm font-bold mb-4">Licenses</div>
          <div className="md:flex">
            <div className="ml-8 mr-4 flex-1">
              <div className="mb-2 flex items-start">
                <button type="button" className="mr-3" aria-label="Call Button">
                  <FontAwesomeIcon icon={faIdCard} size="1x" />
                </button>
                <div>
                  <div>Company License</div>
                  <div>C24074701</div>
                </div>
              </div>
              <div className="flex items-start">
                <button type="button" className="mr-3" aria-label="Call Button">
                  <FontAwesomeIcon icon={faIdCard} size="1x" />
                </button>
                <div>
                  <div>Private Security License</div>
                  <div>01267123</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
